import React from "react";
import { HStack, Link, Text, Stack } from "@chakra-ui/react";
import MailIcon from "../icons/mail.svg";
import PhoneIcon from "../icons/phone-filled.svg";
import SkypeIcon from "../icons/skype.svg";
import TwitterIcon from "../icons/twitter.svg";
import LinkedinIcon from "../icons/linkedin.svg";

export const ContactsStrip: React.FC = () => (
	<Stack spacing={{ base: "20px", md: "40px" }} direction={{ base: "column", md: "row" }}>
		{/*<HStack zIndex={10}>*/}
		{/*	<PhoneIcon />*/}
		{/*	<Link href="tel:+380665106375" color="white" fontSize="16px">*/}
		{/*		+38 066 510 63 75*/}
		{/*	</Link>*/}
		{/*</HStack>*/}
		{/*<HStack>*/}
		{/*	<SkypeIcon />*/}
		{/*	<Text color="white" fontSize="16px">*/}
		{/*		legiosoft*/}
		{/*	</Text>*/}
		{/*</HStack>*/}
		<HStack spacing="20px">
			<Link href="https://twitter.com/legiosoft" isExternal zIndex={10}>
				<TwitterIcon />
			</Link>
			<Link href="https://www.linkedin.com/company/legio-soft" isExternal zIndex={10}>
				<LinkedinIcon />
			</Link>
		</HStack>
	</Stack>
);
