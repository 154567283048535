import React from "react";
import { FormControl, FormErrorMessage, Textarea, TextareaProps } from "@chakra-ui/react";

interface IInputTextAreaProps extends TextareaProps {
	errorMsg?: string;
}

export const InputTextArea = React.forwardRef<null, IInputTextAreaProps>(
	({ errorMsg, ...rest }, ref) => {
		return (
			<FormControl isInvalid={!!errorMsg} mb={!errorMsg ? "29px" : 0}>
				<Textarea
					border="2px solid white"
					borderRadius={0}
					color="white"
					ref={ref}
					{...rest}
				/>
				<FormErrorMessage>{errorMsg}</FormErrorMessage>
			</FormControl>
		);
	},
);

InputTextArea.displayName = "InputTextArea";
