import React from "react";
import { Flex, IconButton, Collapse, Stack, Center, Box, useDisclosure } from "@chakra-ui/react";
import { HamburgerIcon, CloseIcon } from "@chakra-ui/icons";
import { links } from "../../constants";
import { MobileNavItem } from "./MobileNavItem";
import { useLocation } from "@reach/router";
import Logo from "../../icons/logo.svg";
import { Link } from "gatsby";

export const MobileHeader: React.FC = () => {
	const { isOpen, onToggle } = useDisclosure();
	const location = useLocation();

	return (
		<>
			<Flex minH="60px" display={{ base: "flex", md: "none" }}>
				<Flex flex={{ base: 1, md: "auto" }} ml={{ base: -2 }}>
					<IconButton
						onClick={onToggle}
						icon={
							isOpen ? (
								<CloseIcon color="primary" w={5} h={5} />
							) : (
								<HamburgerIcon color="primary" w={7} h={7} />
							)
						}
						variant="ghost"
						aria-label="Toggle Navigation"
						_focus={{ boxShadow: "none" }}
						_active={{ background: "transparent" }}
						_hover={{ background: "transparent" }}
					/>
				</Flex>
				<Center flex={{ base: 1 }} justify={{ base: "center", md: "start" }}>
					<Link to="/">
						<Logo height="40" />
					</Link>
				</Center>
				<Box flex={1} />
			</Flex>
			<Collapse in={isOpen} animateOpacity>
				<Stack p={4} display={{ md: "none" }}>
					{links.map(navItem => (
						<MobileNavItem
							key={navItem.label}
							label={navItem.label}
							href={navItem.destination}
							activePath={location.pathname}
						/>
					))}
				</Stack>
			</Collapse>
		</>
	);
};
