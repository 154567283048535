import React from "react";
import { HStack, Text, Box, Link } from "@chakra-ui/react";
import SkypeIcon from "../icons/skype.svg";
import MailIcon from "../icons/mail.svg";
import PhoneIcon from "../icons/phone-filled.svg";
import LocationIcon from "../icons/location.svg";

interface ICountryContactsProps {
	countryName?: string;
	address?: string;
	email?: string;
	phone?: string;
	skype?: string;
	link?: string;
}

export const CountryContacts: React.FC<ICountryContactsProps> = ({
	countryName,
	address,
	email,
	phone,
	skype,
}) => {
	const credits = [
		{
			icon: <LocationIcon />,
			legend: "Address:",
			value: address,
		},
		{
			icon: <MailIcon />,
			legend: "Mail Us:",
			value: email,
			link: `mailto:${email}`,
		},
		{
			icon: <PhoneIcon />,
			legend: "Phone:",
			value: phone,
		},
		{
			icon: <SkypeIcon />,
			legend: "Skype:",
			value: skype,
		},
	];

	return (
		<Box mt="60px">
			<Text color="primary" fontSize="20px" fontFamily="Poppins" lineHeight="25px" mb="32px">
				{countryName}
			</Text>
			{credits.map(
				elem =>
					elem.value && (
						<HStack key={elem.legend} spacing="15px" mb="25px">
							{elem.icon}
							<Text
								color="white"
								fontFamily="Poppins"
								fontSize="20px"
								lineHeight="25px"
							>
								{elem.legend}
							</Text>
							{elem.link ? (
								<Link href={elem.link} color="white" fontSize="16px">
									{elem.value}
								</Link>
							) : (
								<Text color="white" fontSize="16px">
									{elem.value}
								</Text>
							)}
						</HStack>
					),
			)}
		</Box>
	);
};
