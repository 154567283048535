import React from "react";
import { Flex, Container } from "@chakra-ui/react";
import Logo from "../../icons/logo.svg";
import { ContactsStrip } from "../ContactsStrip";
import { Link } from "gatsby";
import { MobileHeader } from "./MobileHeader";

export const Header: React.FC = () => (
	<Container maxW="container.xl" zIndex={9999} position="relative">
		<Flex
			display={{ base: "none", md: "flex" }}
			align="center"
			justifyContent="space-between"
			py="30px"
		>
			<Link to="/">
				<Logo />
			</Link>
			<ContactsStrip />
		</Flex>
		<MobileHeader />
	</Container>
);
