import React from "react";
import { Flex, Container, Link, Box, Stack } from "@chakra-ui/react";
import Logo from "../icons/logo.svg";
import { Link as GLink } from "gatsby";
import { ContactsStrip } from "./ContactsStrip";
import { links } from "../constants";

export const Footer: React.FC = () => (
	<Box borderTop="1px solid #393939">
		<Container maxW="container.xl">
			<Flex
				align="center"
				justifyContent="space-between"
				py="50px"
				flexDirection={{ base: "column-reverse", md: "row" }}
			>
				<GLink to="/">
					<Logo />
				</GLink>
				<Stack
					direction={{ base: "row-reverse", md: "column" }}
					w={{ base: "100%", md: "auto" }}
					justifyContent={{ base: "space-between" }}
					mb={{ base: "20px", md: 0 }}
				>
					<ContactsStrip />
					<Stack
						mt="25px"
						spacing={{ base: "20px", md: "50px" }}
						direction={{ base: "column", md: "row" }}
					>
						{links.map(elem => (
							<Link
								as={GLink}
								fontFamily="Poppins"
								fontWeight="600"
								color="white"
								to={elem.destination}
								key={elem.destination}
							>
								{elem.label}
							</Link>
						))}
					</Stack>
				</Stack>
			</Flex>
		</Container>
	</Box>
);
