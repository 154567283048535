import React from "react";
import { HStack, Link } from "@chakra-ui/react";
import { Link as GLink } from "gatsby";
import { links } from "../constants";

interface ILinksStripProps {
	active: string;
}

export const LinksStrip: React.FC<ILinksStripProps> = ({ active }) => (
	<HStack
		spacing="50px"
		my="50px"
		display={{ base: "none", md: "flex" }}
		zIndex={100}
		position="inherit"
	>
		{links.map(elem => (
			<Link
				key={elem.destination}
				as={GLink}
				to={elem.destination}
				color={active === elem.label ? "primary" : "white"}
				fontFamily="Poppins"
				fontWeight="600"
				transition="all .4s"
				_hover={{
					color: "primary",
				}}
			>
				{elem.label}
			</Link>
		))}
	</HStack>
);
