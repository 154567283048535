import React from "react";
import { Flex, Text } from "@chakra-ui/react";
import { Link } from "gatsby";

interface IMobileNavItemProps {
	label: string;
	href: string;
	activePath: string;
}

export const MobileNavItem: React.FC<IMobileNavItemProps> = ({ label, href, activePath }) => (
	<Flex py={2} as={Link} to={href} justify="space-between" align="center">
		<Text
			color={activePath === href ? "primary" : "white"}
			fontFamily="Poppins"
			fontWeight="600"
		>
			{label}
		</Text>
	</Flex>
);
