import React from "react";
import { FormControl, FormLabel, Input, FormErrorMessage, InputProps } from "@chakra-ui/react";

interface IInputTextProps extends InputProps {
	errorMsg?: string;
}

export const InputText = React.forwardRef<null, IInputTextProps>(({ errorMsg, ...rest }, ref) => {
	return (
		<FormControl isInvalid={!!errorMsg} mb={!errorMsg ? "29px" : 0}>
			<Input
				border="2px solid white"
				borderRadius={0}
				color="white"
				ref={ref}
				_focus={{ borderColor: "primary", boxShadow: "0 0 0 1px #049283" }}
				{...rest}
			/>
			<FormErrorMessage>{errorMsg}</FormErrorMessage>
		</FormControl>
	);
});

InputText.displayName = "InputText";
