import React from "react";
import { Helmet } from "react-helmet";
import { useStaticQuery, graphql } from "gatsby";

type MetaItem = {
	name: string;
	content: string;
};

type SEOProps = {
	title?: string;
	description?: string;
	keywords?: string[];
	meta?: MetaItem[];
};

const SEO: React.FC<SEOProps> = props => {
	const { site } = useStaticQuery(graphql`
		query {
			site {
				siteMetadata {
					title
					description
					keywords
				}
			}
		}
	`);

	const { title, description, meta = [] } = site;
	const siteTitle = props.title || title;
	const siteDescription = props.description || description;
	const metaData = [
		{
			name: "description",
			content: siteDescription,
		},
		{
			name: "og:title",
			content: siteTitle,
		},
		{
			name: "twitter:card",
			content: "summary",
		},
		{
			name: "twitter:creator",
			content: "LegioSoft",
		},
		{
			name: "twitter:title",
			content: title,
		},
		{
			name: "twitter:description",
			content: siteDescription,
		},
		{
			property: "og:description",
			content: siteDescription,
		},
		{
			property: "og:type",
			content: "website",
		},
		{
			name: "og:description",
			content: siteDescription,
		},
		{
			name: "keywords",
			content: site.siteMetadata.keywords.join(","),
		},
	].concat(meta);

	const linkData = [
		{
			rel: "shortcut icon",
			href: "favicon.ico",
		},
		{
			rel: "apple-touch-icon",
			href: "icons/apple-touch-icon.png",
		},
	];
	return (
		<Helmet
			htmlAttributes={{ lang: "en" }}
			title={siteTitle}
			// titleTemplate={`%s | ${siteTitle}`}
			meta={metaData}
			link={linkData}
		/>
	);
};

export { SEO };
